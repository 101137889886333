import api from "../axios_service.js";

export async function fetchEmpresa(filtros) {
  const response = await api.get(`/gestor/empresa${filtros ? filtros : ""}`);
  return response.data.result;
}

export function putEmpresa(id, empresa) {
  let body = {};
  for (let key in empresa) {
    body[key] = empresa[key];
  }
  return api.put(`/gestor/empresa/${id}`, body);
}

export async function postEmpresa(empresa) {
  const response = await api.post("/gestor/empresa/add", empresa);
  return response;
}

export async function uploadLogo(id, fd) {
  const response = await api.post(`/gestor/empresa/${id}/logo`, fd);
  return response;
}
