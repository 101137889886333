<template>
  <v-container>
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-domain</v-icon>
        {{ empresa.nome_fantasia }}
        <v-spacer></v-spacer>
        <v-btn @click="gerarpdf" icon large>
          <v-icon>mdi-file-pdf-box</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-10">
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col style="max-height: 300px" cols="12" md="3">
              <!-- <h1 class="text-h6 font-weight-bold mb-4">Logo</h1> -->
              <v-card
                flat
                class="fill-height d-flex align-center justify-center"
                style="position:relative;"
              >
                <v-card flat>
                  <v-img
                    :src="
                      empresa.logo
                        ? empresa.logo
                        : require('@/assets/avatar_placeholder.png')
                    "
                    contain
                  ></v-img>
                </v-card>

                <v-btn
                  fab
                  dark
                  x-small
                  absolute
                  bottom
                  right
                  class="mb-3"
                  @click="onButtonClick"
                >
                  <input
                    accept="image/*"
                    type="file"
                    class="d-none"
                    ref="uploader"
                    @change="onFileChanged"
                  />
                  <v-icon>mdi-camera</v-icon>
                </v-btn>
              </v-card>
            </v-col>

            <v-col cols="12" md="9" class="px-3">
              <h1 class="text-h6 font-weight-bold mb-3">Dados</h1>
              <v-row>
                <!--Razao Social -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Razão Social"
                    v-model="empresa.razao_social"
                    dense
                    :rules="formRules"
                    required
                  >
                  </v-text-field>
                </v-col>

                <!--Nome Fantasia -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Nome de Fantasia"
                    v-model="empresa.nome_fantasia"
                    dense
                    :rules="formRules"
                    required
                  >
                  </v-text-field>
                </v-col>

                <!--CNPJ -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-mask="`##.###.###/####-##`"
                    label="CNPJ"
                    v-model="empresa.cnpj"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--Inscricao Estadual -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Inscricao Estadual"
                    v-mask="`#########.##-##`"
                    v-model="empresa.inscricao_estadual"
                    dense
                  ></v-text-field>
                </v-col>

                <!--CPF -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-mask="`###.###.###-##`"
                    label="CPF"
                    v-model="empresa.cpf"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--RG -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-mask="`#######-#`"
                    label="RG"
                    v-model="empresa.rg"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--Ramo de Negocio -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Ramo de Negócio"
                    v-model="empresa.ramo_negocio"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--E-mail  -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="empresa.email"
                    label="E-mail"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- telefone -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="empresa.telefone"
                    v-mask="`(##) ####-####`"
                    label="Telefone"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- celular -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="empresa.celular"
                    v-mask="`(##) #.####-####`"
                    label="Celular"
                    dense
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>

                <!-- whatsapp -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="empresa.whatsapp"
                    v-mask="`(##) #.####-####`"
                    label="Whatsapp"
                    dense
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>

                <!--Telegram -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="empresa.telegram"
                    v-mask="`(##) #.####-####`"
                    label="Telegram"
                    dense
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12"><v-divider></v-divider></v-col>

            <!--Endereco -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">Endereço</h1>
              <v-row>
                <!-- CEP -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cep"
                    v-mask="`#####-###`"
                    label="CEP"
                    dense
                    append-icon="mdi-magnify"
                    @keyup.enter="fetchCep"
                    @click:append="fetchCep"
                    :loading="loadingCep"
                  ></v-text-field>
                </v-col>

                <!--endereco -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Endereço"
                    v-model="empresa.endereco"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!--('numero')- -->
                <v-col cols="12" md="3">
                  <v-text-field label="Número" v-model="empresa.numero" dense>
                  </v-text-field>
                </v-col>

                <!--('('bairro')-')- -->
                <v-col cols="12" md="3">
                  <v-text-field label="Bairro" v-model="empresa.bairro" dense>
                  </v-text-field>
                </v-col>

                <!--('('complemento')-')- -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Complemento"
                    v-model="empresa.complemento"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Cidade -->
                <v-col cols="12" md="3">
                  <v-text-field label="Cidade" v-model="empresa.cidade" dense>
                  </v-text-field>
                </v-col>

                <!-- UF -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="empresa.uf"
                    label="UF"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12"><v-divider></v-divider></v-col>

            <!--Contatos -->
            <v-col cols="12" class="pa-3">
              <h1 class="text-h6 font-weight-bold mb-3">Mídia</h1>
              <v-row>
                <!--Site -->
                <v-col cols="12" md="12">
                  <v-text-field label="Site" v-model="empresa.site" dense>
                  </v-text-field>
                </v-col>

                <!-- Instagram -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Instagram"
                    v-model="empresa.instagram"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Facebook -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Facebook"
                    v-model="empresa.facebook"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Linkedin -->
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Linkedin"
                    v-model="empresa.linkedin"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <!-- Youtube -->
                <v-col cols="12" md="3">
                  <v-text-field label="Youtube" v-model="empresa.youtube" dense>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          @click="updateEmpresa"
          :disabled="!validForm"
          color="secondary"
          class="white--text "
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      :title="empresa.nome_fantasia"
    />
  </v-container>
</template>

<script>
import { putEmpresa, fetchEmpresa, uploadLogo } from "@/api/gestor/empresa.js";
import { inputRequired } from "@/helpers/utils.js";
import { buscaCep } from "@/api/geral/buscaCep.js";

const pdfGenerator = () =>
  import(/* webpackChunkName: "relatorio" */ "./pdf/empresaPdf.js");

export default {
  name: "Empresa",

  data() {
    return {
      tab: 0,
      loading: false,
      logo: "",
      empresa: {
        logo: "",
        nome_fantasia: "",
        razao_social: "",
        cpf: "",
        rg: "",
        cnpj: "",
        status: 1,
        inscricao_estadual: "",
        ramo_negocio: "",
        site: "",
        instagram: "",
        facebook: "",
        linkedin: "",
        youtube: "",
        endereco: "",
        numero: "",
        bairro: "",
        complemento: "",
        cep: "",
        uf: "",
        cidade: "",
        celular: "",
        whatsapp: "",
        telegram: "",
        email: "",
      },
      validForm: true,
      formRules: [inputRequired],
      pdfDialog: false,
      pdfData: "",
      cep: "",
      loadingCep: false,
    };
  },

  components: {
    PdfDialogComp: () => import("../../../components/PdfDialogComp.vue"),
  },

  computed: {},

  methods: {
    fetchCep() {
      if (this.cep && this.cep.length === 9) {
        this.empresa.cep = "";
        this.empresa.cidade = "";
        this.empresa.bairro = "";
        this.empresa.uf = "";
        this.empresa.endereco = "";

        this.loadingCep = true;
        buscaCep(this.cep)
          .then((response) => {
            this.empresa.cep = response.cep;
            this.empresa.cidade = response.city;
            this.empresa.bairro = response.neighborhood;
            this.empresa.uf = response.state;
            this.empresa.endereco = response.street;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$toast.error("CEP não encontrado!");
            }
          })
          .finally(() => {
            this.loadingCep = false;
          });
      }
    },

    getEmpresa() {
      this.loading = true;
      fetchEmpresa()
        .then((response) => {
          if (response) {
            this.empresa = response;
            this.cep = this.empresa.cep;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    updateEmpresa() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        putEmpresa(this.empresa.id, this.empresa)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Atualizado com sucesso");
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];
      const vue = this;
      const reader = new FileReader();
      let logo64 = null;
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event) => {
        logo64 = event.target.result;
        vue.upLogo(file, logo64);
      };
    },

    upLogo(logo, logo64) {
      const fd = new FormData();
      fd.append("logo", logo, logo.name);
      fd.append("logo64", logo64);

      uploadLogo(this.empresa.id, fd)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Logo atualizada com sucesso!");
            this.getEmpresa();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async gerarpdf() {
      try {
        this.loading = true;
        this.$Progress.start();

        await pdfGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.empresa).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
            this.$Progress.finish();
          });
      } catch (error) {
        // console.log(error);
        this.loading = false;
        this.$Progress.fail();
      }
    },
  },

  mounted() {
    if (!this.empresa.razao_social) {
      this.getEmpresa();
    } else {
      this.cep = this.empresa.cep;
    }
  },

  beforeRouteEnter(to, from, next) {
    fetchEmpresa()
      .then((response) => {
        if (response) {
          next((comp) => {
            comp.empresa = response;
          });
        } else {
          next({ path: "/gestor/empresa/adicionar" });
        }
      })
      .catch(() => {
        next({ path: "/gestor/home" });
      });
  },
};
</script>

<style lang="scss" scoped></style>
